<template>
  <div>
    <b-modal
      v-if="wishlist._id"
      :id="`modal-rename-wishlist-${wishlist._id}`"
      centered
      modal-class="custom-modal-variant"
      ok-variant="secondary-info"
      cancel-variant="outline-secondary-info"
      ok-title="Rename"
      header-close-variant="cader-gray"
      cancel-title="Close"
      footer-class="elements-center border-top-0 pt-0"
      @ok="renameWishlist()"
    >
      <template #modal-title>
        <div class="elements-center color-dark-blue">
          <span class="share-rounded-icon rename">
            <pencil-icon />
          </span>
          <span> Rename {{ getWishlistTitle(isRetailer) }} </span>
        </div>
      </template>
      <b-form-group
        :label="`${getWishlistTitle(isRetailer)} Name`"
        label-class="color-neutral-black font-weight-bold"
      >
        <b-form-input
          id="collection-name"
          v-model="wishlistNewName"
          name="name"
          class="rename-input"
          :placeholder="`Enter ${getWishlistTitle(isRetailer)} Name`"
        />
      </b-form-group>
    </b-modal>
    <div v-if="wishlist._id">
      <download-wishlist-modal-v-2
        :modal-id="`modal-download-wishlist-${wishlist._id}`"
        :options="DOWNLOAD_OPTIONS.WISHLIST"
        :is-downloading="isDownloading"
        @on-click-download="downloadWishlist"
      />
    </div>
    <b-modal
      v-if="wishlist._id"
      :id="`modal-delete-wishlist-${wishlist._id}`"
      centered
      modal-class="custom-modal-variant"
      ok-variant="danger"
      cancel-variant="outline-secondary-black"
      ok-title="Yes, delete it"
      header-close-variant="cader-gray"
      cancel-title="Close"
      footer-class="elements-center border-top-0 pt-0"
      @ok="deleteWishlist()"
    >
      <template #modal-title>
        <div class="elements-center color-dark-black">
          <span class="share-rounded-icon danger">
            <delete-icon />
          </span>
          <span> Delete {{ getWishlistTitle(isRetailer) }} </span>
        </div>
      </template>
      <div class="color-neutral-black">
        <b-card-text>
          This {{ getWishlistTitle(isRetailer) }} will be permanently deleted.
        </b-card-text>
        <b-card-text> Are you sure you want to proceed? </b-card-text>
      </div>
    </b-modal>
    <b-modal
      :id="getShareWislistModalId"
      hide-header
      hide-footer
      centered
      @hide="onCloseShareWishlist"
    >
      <div
        class="pb-5-px border-bottom d-flex justify-content-between align-items-center"
      >
        <div class="elements-center">
          <span class="share-rounded-icon">
            <share-icon />
          </span>
          <span class="custom-modal-heading color-dark-blue">
            Share {{ getWishlistTitle(isRetailer, isMultipleSelections) }}
          </span>
        </div>
        <feather-icon
          class="float-right color-cader-gray cursor-pointer"
          icon="XIcon"
          size="24"
          @click="$bvModal.hide(getShareWislistModalId)"
        />
      </div>
      <validation-observer
        ref="collectionForm"
        #default="{ invalid }"
      >
        <div
          class="mt-1"
        >
          <b-form-group>
            <template #label>
              Select Email <span class="text-danger"> * </span>
            </template>
            <b-dropdown
              class="filter-dropdown"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between text-overflow p-1-px"
                  :class="{ 'has-value': retailerId }"
                >
                  <span> {{ retailerId ? `${selectedRetailer.email} - ${selectedRetailer.entityName}`: 'Select Email' }} </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-form>
                <b-form-input
                  v-model="searchText"
                  placeholder="Search retailer"
                />
                <feather-icon
                  icon="SearchIcon"
                  class="dropdown-form-search-icon"
                  size="18"
                />
              </b-dropdown-form>
              <template v-if="allDirectRetailers.length">
                <b-dropdown-item
                  v-for="option in allDirectRetailers"
                  :id="option.retailerId"
                  :key="option.retailerId"
                  :active="option.retailerId === retailerId"
                  @click="onSelectRetailer(option)"
                >
                  <div class="text-overflow">
                    {{ option.email }} - {{ option.entityName }}
                  </div>
                  <b-tooltip
                    class="font-weight-bolder"
                    :target="option.retailerId"
                    placement="bottom"
                  >
                    {{ option.email }} - {{ option.entityName }}
                  </b-tooltip>
                </b-dropdown-item>

              </template>
              <b-dropdown-item
                v-else
                class="text-center"
                disabled
              >
                No retailers found
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </div>

        <div class="elements-center p-1">
          <b-button
            variant="outline-secondary-info"
            @click="$bvModal.hide(getShareWislistModalId)"
          >
            Close
          </b-button>
          <b-button
            variant="secondary-info"
            class="ml-1"
            :disabled="isSharing || !email || invalid"
            @click="onClickShareWishlist"
          >
            {{ isSharing ? 'Sharing' : 'Share' }}
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
    <select-stores-modal
      v-if="wishlist._id"
      :modal-id="`modal-select-stores-${wishlist._id}`"
      modal-title="Download Wishlist"
      ok-btn-text="Download"
      :store-list="storesList"
      :is-downloading="isDownloading"
      @on-click-download="downloadOrderForm"
    />
  </div>
</template>

<script>
import DeleteIcon from '@/@core/assets/svg-components/DeleteIcon.vue'
import PencilIcon from '@/@core/assets/svg-components/PencilIcon.vue'
import ShareIcon from '@/@core/assets/svg-components/ShareIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics, {
getSelectionOrWishlistMixpanelObj,
} from '@/@core/utils/analytics'
import {
apiToastError,
apiToastSuccess,
apiToastWarning,
} from '@/@core/utils/toast'
import {
formatObject,
getWishlistTitle,
parseErrorObject,
searchDirectRetailer,
} from '@/@core/utils/utils'
import { kpEndpoint, kpParams, kpRequest } from '@/axios'
import constants, { DOWNLOAD_TYPES, FILE_FORMATS } from '@/constants'
import store from '@/store'
import { DELETE_WISHLIST, DOWNLOAD_WISHLIST } from '@/store/modules/shop.module'
import {
BButton,
BCardText,
BDropdown,
BDropdownForm,
BDropdownItem,
BFormGroup,
BFormInput,
BModal,
VBModal,
BTooltip,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import NotificationMixin from '@/views/mixins/NotificationMixin.vue'
import { SHOW_SALES_ANALYTICS_NAV_INDICATOR } from '@/store/modules/notification.module'
import { MULTI_SELECT_MODAL_ID, RESET_SELECTED_SELECTIONS, SET_SELECTED_RETAILER } from '@/store/modules/multi-select.module'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import DownloadWishlistModalV2 from './DownloadWishlistModalV2.vue'
import SelectStoresModal from '../checkout-v2/components/SelectStoresModal.vue'

const { ROLES } = c

const { DOWNLOAD_OPTIONS } = constants
const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES
const { SHARE_MULTIPLE_SELECTIONS } = constants.TRACKS.ACTIONS

export default {
  name: 'WishlistAction',
  components: {
    BCardText,
    BFormInput,
    BModal,
    FeatherIcon,
    BFormGroup,
    BButton,
    ValidationObserver,
    ShareIcon,
    PencilIcon,
    DeleteIcon,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BTooltip,
    DownloadWishlistModalV2,
    SelectStoresModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixinVue, NotificationMixin],
  props: {
    wishlist: {
      type: Object,
      default() {
        return {}
      },
    },
    isMultipleSelections: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DOWNLOAD_OPTIONS,
      wishlistNewName: this.wishlist.name || '',
      isDownloading: false,
      selectedRetailer: {},
      email: '',
      getWishlistTitle,
      isSharing: false,
      allDirectRetailers: [],
      searchText: '',
      retailerId: null,
    }
  },
  computed: {
    selectedSelections() {
        return this.$store.getters?.getSelectedSelections || []
    },
    getShareWislistModalId() {
      return this.isMultipleSelections
        ? MULTI_SELECT_MODAL_ID
        : `modal-share-wishlist-${this.wishlist._id}`
    },
    currentWishlist() {
      return this.$store.getCurrentCheckoutWishlist
    },
    ...mapState({
      directRetailers: state => state.connections.directRetailers || [],
      profileData: state => state.auth.profileData,
      wishlists: state => state.shop.wishlists,
    }),
    storesList() {
      return this.profileData.userAssociations.filter(userAsso => userAsso.entityType === ROLES.RETAILER).map(userAsso => ({
        storeId: userAsso.entityId,
        storeName: userAsso?.entity?.entityName,
      }))
    },
    shareSelectionSuccessMessage() {
      return `${getWishlistTitle(this.isRetailer, this.isMultipleSelections)} has been shared successfully`
    },
  },
  watch: {
    directRetailers: {
      handler() {
        this.allDirectRetailers = searchDirectRetailer(
          this.directRetailers,
        )
      },
      deep: true,
    },
    searchText() {
      this.searchRetailer()
    },
  },
  mounted() {
    this.allDirectRetailers = searchDirectRetailer(
        this.directRetailers,
    )
  },
  emits: ['open-referral-model', 'fetchWishlists', 'renamed', 'on-open-errors-popup'],
  methods: {
    onCloseShareWishlist() {
      this.resetInputs()
      analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLICKS_CANCEL_BTN_ON_SHARE_POPUP)
    },
    onSelectRetailer(retailer) {
      if (this.selectedRetailer?.email === retailer.email) {
        this.selectedRetailer = {}
        this.retailerId = null
        this.email = ''
      } else {
        this.retailerId = retailer.retailerId
        this.selectedRetailer = retailer
        this.email = retailer.email
      }
      this.$store.commit(SET_SELECTED_RETAILER, retailer)
      this.searchText = ''
      eventPayload = {
        retailer,
      }
      if (this.isMultipleSelections) {
        eventPayload.selections = this.getSelectedSelections()
      } else {
        eventPayload.selection = this.wishlist
      }
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_SELECT_EMAIL_TO_SHARE_SELECTION, eventPayload,
      )
    },
    resetInputs() {
      this.email = ''
      this.selectedRetailer = {}
      this.retailerId = null
    },
    renameWishlist() {
      kpRequest({
        ...kpParams(kpEndpoint.wishlist.rename, this.wishlist._id),
        payload: {
          name: this.wishlistNewName,
        },
      })
        .then(() => {
          this.wishlist.name = this.wishlistNewName
          this.$emit('renamed', this.wishlist)
          apiToastSuccess(`${getWishlistTitle(this.isRetailer)} renamed successfully`)
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    onClickShareWishlist() {
      if (this.isMultipleSelections) {
        this.shareMultipleSelections()
      } else {
        this.shareWishlist()
      }
    },
    shareMultipleSelections() {
      this.isSharing = true
      const payload = this.buildPayload()
      analytics.track(
        SHARE_MULTIPLE_SELECTIONS.BRAND_CLICKS_SHARE_SELECTION_BTN_ON_SHARE_POPUP, { payload },
      )
      kpRequest({
        ...kpEndpoint.wishlist.shareMultiple,
        payload,
      })
        .then(() => {
          this.handleSuccessMultipleShare()
          analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_SHARED_SELECTION_SUCCESSFULLY, payload)
        })
        .catch(err => {
          this.handleErrorMultipleShare(err)
        })
    },
    buildPayload() {
      const payload = {
        email: this.email,
        selectionIds: this.selectedSelections,
      }
      if (this.retailerId) {
        payload.retailerId = this.retailerId
      }
      return payload
    },
    handleSuccessMultipleShare() {
      this.isSharing = false
      this.$store.commit(SHOW_SALES_ANALYTICS_NAV_INDICATOR)
      this.handlePartialSuccessMultipleShare(null)
      this.$emit('fetchWishlists')
      this.hideModalAndResetMultipleShare()
    },
    handleErrorMultipleShare(err) {
      this.isSharing = false
      if (this.isBadRequestWithErrors(err)) {
        this.handlePartialSuccessMultipleShare(err)
      } else {
        apiToastError(err)
        analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_SHARED_SELECTION_FAILED, { error: parseErrorObject(err) })
      }
      this.hideModalAndResetMultipleShare()
    },
    isBadRequestWithErrors(err) {
      return err?.response?.status === 400 && err?.response?.data?.data?.length
    },
    getSelectedSelections() {
      return this.selectedSelections.map(selectionId => this.wishlists.find(wishlist => wishlist._id === selectionId))
    },
    handlePartialSuccessMultipleShare(err = null) {
      const selectedWishlists = this.getSelectedSelections()
      if (!err) {
        this.$emit('on-open-errors-popup', selectedWishlists)
        return
      }
      const errors = err?.response?.data?.data
      const errorMessages = selectedWishlists.map(wishlist => {
        const error = errors.find(e => e.selectionId === wishlist._id)
        return {
          _id: wishlist._id,
          isError: !!error,
          name: wishlist?.name,
        }
      })
      this.$emit('fetchWishlists')
      this.$emit('on-open-errors-popup', errorMessages)
      analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_SHARED_SELECTION_FAILED, { errorMessages })
    },
    hideModalAndResetMultipleShare() {
      this.$bvModal.hide(this.getShareWislistModalId)
      this.resetInputs()
      this.$store.commit(RESET_SELECTED_SELECTIONS)
    },
    shareWishlist() {
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_CLICKS_SHARE_SELECTION_BTN, { selection: this.wishlist },
)
      this.isSharing = true
      const payload = {
        email: this.email,
      }
      if (this.retailerId) {
        payload.retailerId = this.retailerId
      }
      kpRequest({
        ...kpParams(kpEndpoint.wishlist.share, this.wishlist._id),
        payload,
      })
        .then(() => {
          this.isSharing = false
          apiToastSuccess(this.shareSelectionSuccessMessage)
          this.$store.commit(SHOW_SALES_ANALYTICS_NAV_INDICATOR)
          this.$emit('fetchWishlists')
          this.$bvModal.hide(this.getShareWislistModalId)
          this.resetInputs()
          analytics.track(
            constants.TRACKS.ACTIONS.SELCTION_SHARED_SUCCESSFULLY, payload,
)
        })
        .catch(err => {
          this.isSharing = false
          if (err.response.status === 441) {
            this.$emit('open-referral-model', this.email, this.wishlist)
            this.resetInputs()
            this.$emit('fetchWishlists')
            this.$bvModal.hide(this.getShareWislistModalId)
          } else {
            apiToastError(err)
          }
        })
    },
    deleteWishlist() {
      store
        .dispatch(DELETE_WISHLIST, this.wishlist._id)
        .then(() => {
          if (
            this.currentWishlist?._id === this.wishlist._id
            && ['wishlist-products', 'selection-products'].includes(
              this.$route.name,
            )
          ) {
            this.$router.push({
              name: this.isRetailer ? 'wishlist' : 'selection',
            })
          } else {
            this.$emit('fetchWishlists')
          }
          apiToastSuccess(`${getWishlistTitle(this.isRetailer)} deleted successfully`)
          analytics.track(
            this.isBrand
              ? constants.TRACKS.ACTIONS.BRAND_DELETES_SELECTION
              : constants.TRACKS.ACTIONS.RETAILER_DELETES_WISHLIST,
            formatObject(
              getSelectionOrWishlistMixpanelObj(this.wishlist, this.isBrand),
            ),
          )
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
    downloadOrderForm(selectedStores = []) {
      this.isDownloading = true
      const downloadType = DOWNLOAD_TYPES.WISHLIST_EXPORT.VALUE
      const payload = {
        downloadType,
        respectiveId: this.wishlist._id,
        fileType: FILE_FORMATS.XLSX,
        entityIds: selectedStores.map(store => store.storeId),
      }
      const params = {
        type: DOWNLOAD_OPTIONS.WISHLIST[2].value,
      }

        store
          .dispatch(DOWNLOAD_WISHLIST, { payload, params })
          .then(res => {
            this.isDownloading = false
            this.$bvModal.hide(`modal-select-stores-${this.wishlist._id}`)
            apiToastSuccess(res.data.message)
            this.notifyDownloadsNavMenu()
            analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_EXPORT_WISHLIST_AS_ORDER_FORM, { payload, params })
          })
          .catch(() => {
            this.isDownloading = false
            this.$bvModal.hide(`modal-select-stores-${this.wishlist._id}`)
          })
    },
    async downloadWishlist(selectedFileFormat) {
      if (this.isRetailer && selectedFileFormat.value === DOWNLOAD_OPTIONS.WISHLIST[2].value) {
        this.$bvModal.hide(`modal-download-wishlist-${this.wishlist._id}`)
        this.$bvModal.show(`modal-select-stores-${this.wishlist._id}`)
        return
      }
      this.isDownloading = true
      const downloadType = this.isBrand ? DOWNLOAD_TYPES.SELECTION_EXPORT.VALUE : DOWNLOAD_TYPES.WISHLIST_EXPORT.VALUE
      const payload = {
        downloadType,
        respectiveId: this.wishlist._id,
        fileType: selectedFileFormat.fileType,
      }
      const params = selectedFileFormat.params || {}
      if (this.isBrand && selectedFileFormat.value === DOWNLOAD_OPTIONS.WISHLIST[2].value) {
        params.isOrderForm = true
      }
        store
          .dispatch(DOWNLOAD_WISHLIST, { payload, params })
          .then(res => {
            this.isDownloading = false
            this.$bvModal.hide(`modal-download-wishlist-${this.wishlist._id}`)
            apiToastSuccess(res.data.message)
            this.notifyDownloadsNavMenu()
          })
          .catch(() => {
            this.isDownloading = false
            this.$bvModal.hide(`modal-download-wishlist-${this.wishlist._id}`)
          })
      // Commented temporarly (7/11/2022)
      // else {
      //   kpApi
      //     .get(kpRequest(kpEndpoint.wishlist.download, this.wishlist._id, selectedFileFormat), {
      //       headers: {
      //         Accept: 'application/zip',
      //       },
      //     })
      //     .then(res => {
      //       downloadZip({
      //         zip: res.data,
      //         fileName: `${getWishlistTitle(this.isRetailer)}-${this.wishlist._id}.zip`,
      //       })
      //       this.isDownloading = false
      //       this.$bvModal.hide(`modal-download-wishlist-${this.wishlist._id}`)
      //       apiToastSuccess(`Your ${getWishlistTitle(this.isRetailer)} ${this.wishlist._id} is being downloaded...`, 'DownloadCloudIcon')
      //       analytics.track(constants.TRACKS.ACTIONS.DOWNLOAD_PRODUCT_IMAGES_WISHLIST, formatObject(this.wishlist))
      //     })
      //     .catch(err => {
      //       apiToastWarning(err)
      //       this.isDownloading = false
      //       this.$bvModal.hide(`modal-download-wishlist-${this.wishlist._id}`)
      //     })
      // }
      if (this.isRetailer) {
        analytics.track(
          constants.TRACKS.ACTIONS.RETAILER_DOWNLOADS_WISHLIST,
          formatObject(
            getSelectionOrWishlistMixpanelObj(this.wishlist, false, {
              downloadFileOption: selectedFileFormat,
            }),
          ),
        )
      }
    },
    searchRetailer() {
      this.allDirectRetailers = searchDirectRetailer(
        this.directRetailers,
        this.searchText,
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
